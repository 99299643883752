<template>
  <div>
    <component v-for='(configuredComponent, index) in components'
      :key='`configure-component-${index}`'
      :is='configuredComponent.componentName'></component>
  </div>
</template>

<script>

export default {
  name: 'MultipleComponentsPage',
  props: {
  },
  computed: {
    components () {
      return []
    }
  },
}
</script>
